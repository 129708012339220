<!-- System: STA
    Purpose: This compoment will display attendance
            reports section.
-->
<template>
  <div>
    <v-row class="mt-4 d-flex justify-end" @click="menu = false">
      <!-- Date Range Picker -->
      <v-col cols="12" sm="12" md="2" offset-md="1" class="mt-n1">
        <label
          class="labels ml-4 d-block font-weight-thin text-left"
          for
          id="cRange"
          >Date Range:</label
        >
        <v-menu id="list-menu" nudge-left="0" :close-on-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-col class="d-flex flex-row-reverse">
              <v-subheader
                class="selected-date"
                v-bind="attrs"
                v-on="on"
                style="width: 100%"
                @click="checkClick"
              >
                {{ dateRangeTitle }}
                <span class="d-flex justify-start ml-auto">
                  <v-icon
                    color="primary"
                    id="rangeBtn"
                    class="mdi-dark mdi-inactive"
                    >mdi-calendar-range</v-icon
                  >
                </span>
              </v-subheader>
            </v-col>
          </template>
          <v-list id="custom-list-style">
            <v-list-item
              style="min-height: 30px"
              v-for="(item, index) in items"
              :key="index"
              @click="checkIfCustomDate(item)"
            >
              <v-list-item-title style="font-size: 13px" class="custom">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog
          ref="dialog"
          v-model="modal_2"
          :return-value.sync="date"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-show="(modal_2_show = false)"
              v-model="date"
              label="Picker in dialog"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeDateModal()">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              id="apply-btn"
              @click="getAttendenceFromCustomDate()"
            >
              Apply
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <!-- Group By Dropdown -->
      <v-col cols="12" sm="12" md="2">
        <v-autocomplete
          outlined
          dense
          class="mt-4"
          v-model="group_by"
          :items="groupByFilter"
          item-text="value"
          item-value="id"
          label="Group By"
        ></v-autocomplete>
      </v-col>
      <!-- Members Dropdown -->
      <v-col cols="12" sm="12" md="2">
        <v-autocomplete
          outlined
          dense
          class="mt-4"
          v-model="selectedMember"
          :items="teamMember"
          :loading="teamMemberLoader"
          item-text="name"
          item-value="id"
          label="Search By Members"
        ></v-autocomplete>
      </v-col>
      <!-- Department Dropdown -->
      <v-col cols="12" sm="12" md="2">
        <v-autocomplete
          outlined
          dense
          class="mt-4 mr-5"
          v-model="department"
          :items="allDepartmentList"
          item-text="name"
          item-value="hash_id"
          label="Search By Department"
        ></v-autocomplete>
      </v-col>
      <!-- Export Button -->
      <v-col
        cols="6"
        sm="6"
        md="2"
        v-if="allAttendenceData && allAttendenceData.length > 0"
      >
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2758f6"
              class="mt-4"
              dark
              v-bind="attrs"
              v-on="on"
              offset-y
            >
              Export As
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="exportToCSV('attendence')">
              <v-list-item-title align="left"> CSV </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportToExcel('attendence')">
              <v-list-item-title align="left"> Excel </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportToCSV('attendenceTable')">
              <v-list-item-title align="left">
                Accumulated CSV
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportToExcel('attendenceTable')">
              <v-list-item-title align="left">
                Accumulated Excel
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <!-- Search Button -->
      <v-col cols="6" sm="6" md="1">
        <v-btn
          primary
          class="active-custom-btn mt-4 ml-n5 px-10 px-sm-16 px-md-3 px-lg-7"
          @click="get_attendance_List"
        >
          <span class="mdi">Search</span>
        </v-btn>
      </v-col>
      <!-- Render If No Attendance Data Found -->
      <div
        class="col-md-12 mt-5 text-center"
        v-if="!attendancedata || attendancedata.length === 0"
      >
        <img
          class="error-img"
          src="../../assets/images/no-activity-found-new.svg"
        />
        <p class="error_text" v-if="toggle_loader === false">Loading Data</p>
        <p class="error_text" v-else>No data found</p>
      </div>
      <v-col cols="12" sm="12" md="12" v-else>
        <!-- Attendance Table -->
        <v-simple-table id="attendence">
          <template v-slot:default>
            <thead style="background-color: #2858f6">
              <tr>
                <th
                  class="text-left display"
                  v-if="group_by == 'none' || group_by == 'name'"
                  @click="sortData('name')"
                >
                  Name <span class="mdi mdi-sort"></span>
                </th>
                <th
                  class="text-left display"
                  v-if="group_by != 'date'"
                  @click="sortData('department_name')"
                >
                  Department <span class="mdi mdi-sort"></span>
                </th>
                <th class="text-left display" @click="sortData('date')">
                  Date <span class="mdi mdi-sort"></span>
                </th>
                <th
                  class="text-left display"
                  v-if="group_by == 'none'"
                  @click="sortData('check_in')"
                >
                  Check In <span class="mdi mdi-sort"></span>
                </th>
                <th
                  class="text-left display"
                  v-if="group_by == 'none'"
                  @click="sortData('check_out')"
                >
                  Check Out <span class="mdi mdi-sort"></span>
                </th>
                <th class="text-left display">Total Time</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in allAttendenceData"
                v-if="toggle_loader == true"
              >
                <td
                  class="text-left"
                  v-if="group_by == 'none' || group_by == 'name'"
                >
                  <v-tooltip class="uner_info_tooltip" top color="#ffffff">
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-if="exportImage"
                        :src="`${url}storage/${userData(item.id)}`"
                        @error="
                          $event.target.src = require('../../assets/images/no-member.svg')
                        "
                        alt="Thumbnail"
                        class="timesheet-image"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span
                      ><UserInfo :member="userDetail(item.id)"></UserInfo
                    ></span>
                  </v-tooltip>
                  <span> {{ item.name }}</span>
                </td>
                <td class="text-left" v-if="group_by != 'date'">
                  {{
                    item.department_name
                      ? item.department_name
                      : "Miscellaneous"
                  }}
                </td>
                <td class="text-left">{{ item.date }}</td>
                <td class="text-left" v-if="group_by == 'none'">
                  {{ item.check_in }}
                </td>
                <td class="text-left" v-if="group_by == 'none'">
                  {{ item.check_out }}
                </td>
                <td class="text-left">{{ item.total_time }}</td>
              </tr>
              <tr v-if="toggle_loader == false">
                <td class="text-center" colspan="6">..........loading</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="table-div">
      <table id="attendenceTable" style="display: none">
        <thead>
          <tr>
            <th class="table-sticky-col-Name custom-padding">Name</th>
            <th class="table-sticky-col-Name custom-padding">Department</th>
            <th class="table-sticky-col-name custom-padding data-align">
              Date
            </th>
            <th
              class="table-sticky-col-name custom-padding data-align"
              v-for="date in rangeDate"
            >
              {{ date }}
            </th>
            <th class="table-sticky-col-name custom-padding data-align">
              Total Time
            </th>
            <th class="table-sticky-col-name custom-padding data-align">
              Total Absent
            </th>
            <th class="table-sticky-col-name custom-padding data-align">
              Employee ID
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attendence in excellData">
            <td>{{ attendence["0"].name }}</td>
            <td>{{ attendence["0"].department_name }}</td>
            <td>Check In - Check Out = Total Time</td>
            <td class="data-align" v-for="date in rangeDate">
              {{ calculateDate(date, attendence) }}
            </td>
            <td>{{ totalTime(attendence) }}</td>
            <td>{{ totalAbsent(rangeDate, attendence) }}</td>
            <td>{{ attendence["0"].emp_id }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import _ from "lodash";
import { mapState } from "vuex";
import { baseURL } from "@/assets/js/urls";
import $ from "jquery";
import UserInfo from "../includes/UserInfo";
import JsonCSV from "vue-json-csv";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    UserInfo,
  },
  data() {
    return {
      menu_2: false,
      modal_2: false,
      teamMemberLoader: false,
      date: [],
      showPicker: false,
      menu: false,
      items: [
        { id: 1, title: "Yesterday" },
        { id: 2, title: "Last 7 Days", value: "" },
        { id: 3, title: "Last 30 Days", value: "" },
        { id: 4, title: "This Month", value: "" },
        { id: 5, title: "Last Month", value: "" },
        { id: 6, title: "Last Year", value: "" },
        { id: 7, title: "Custom Range" },
      ],
      start: moment().subtract(1, "month").startOf("month"),
      end: moment().subtract(1, "month").endOf("month"),
      start_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
      end_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
      selectedMember: "all",
      department: "all",
      members_lists: [],
      departmentList: [],
      filteredData: [],
      groupByFilter: [
        { id: "none", value: "None" },
        { id: "name", value: "Name" },
        { id: "department", value: "Department" },
        { id: "date", value: "Date" },
      ],
      importData: [
        { id: "csv", value: "Csv" },
        { id: "excell", value: "Excell" },
      ],
      tableShow: false,
      attendancedata: [],
      attendanceList: [],
      excellData: [],
      rangeDate: null,
      getAllKeys: [],
      group_by: "none",
      attendenceGroupBy: [],
      userIndex: null,
      userInfo: [],
      exportImage: true,
      toggle_loader: false,
      dateRangeTitle: "",
    };
  },
  computed: {
    ...mapState("custom", ["team_members_projects_list"]),
    selectedCompany() {
      return this.$store.state.custom.selected_company;
    },
    selectedCompanyUserStatus() {
      const company = this.$store.state.custom.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    /**
     * Return the custom list of
     * team members
     */
    teamMember() {
      let newArray = [];
      this.filteredData.map((member) => {
        newArray.push(member);
      });
      return newArray;
    },
    /**
     * Return the custom
     * deparment list
     */
    allDepartmentList() {
      let arrayDep = [];
      let allTeamMembers = {
        hash_id: "all",
        name: "All Departments",
      };
      arrayDep.push(allTeamMembers);
      this.departmentList.map((department) => {
        arrayDep.push(department);
      });
      return arrayDep;
    },
    /**
     * Return the base url of api
     */
    url() {
      return baseURL.API_URL;
    },
    /**
     * Return all attendence Data
     */
    allAttendenceData() {
      let attendence;
      if (this.group_by == "none") {
        return this.attendancedata;
      }
      if (this.group_by == "date") {
        // attendence = _.orderBy(this.attendenceGroupBy, ["date"], ["asc"]);
        attendence = this.attendenceGroupBy;
      } else {
        attendence = this.attendenceGroupBy;
      }
      return attendence;
    },
  },
  watch: {
    group_by() {
      this.groupByAttendence();
    },
  },
  mounted() {
    window.addEventListener("click", () => {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "none") {
        document.getElementById("rangeBtn").click();
        x.style.display = "none";
      }
    });
    this.get_attendance_List();
    if (
      !this.$store.state.custom.departments ||
      this.$store.state.custom.departments.length == 0
    ) {
      this.get_department_list();
    } else {
      this.departmentList = this.$store.state.custom.departments;
    }
    this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
    this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
    this.dateRangeTitle = "Yesterday";
    this.selectedDateTeamMembers();
  },
  methods: {
    /*
      Fetch the lastest working members
      data according to given date
     */
    selectedDateTeamMembers() {
      let data = {
        company_id: this.$route.params.id,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.teamMemberLoader = true;
      this.$store
        .dispatch("custom/getLastWorkingDaysMember", data)
        .then((response) => {
          this.teamMemberLoader = false;
          this.filteredData = response.data.data.team_members;
          if (this.filteredData[0].id != "all") {
            let allMember = {
              id: "all",
              name: "All Members",
            };
            this.filteredData.unshift(allMember);
          }
          this.teamMemberLoader = false;
        })
        .catch(() => {
          this.teamMemberLoader = false;
        });
    },
    userDetail(id) {
      return this.$store.state.custom.get_team_members_list.find(
        (data) => data.id == id
      );
    },
    closeDateModal() {
      this.modal_2 = false;
      this.menu = false;
    },
    checkClick() {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "block") {
        x.style.display = "block";
      }
    },
    import_to(event, index) {
      if (event == "csv") {
        this.exportToCSV("attendence");
      } else {
        this.exportToExcel("attendence");
      }
    },
    /**
     * This function is resposible for
     * get report w.r.t custom date
     */
    getAttendenceFromCustomDate() {
      this.modal_2 = false;
      this.start_date = moment(this.date[0]).format("YYYY-MM-DD");
      this.end_date = moment(this.date[1]).format("YYYY-MM-DD");
      if (this.start_date.toString() === "Invalid date") {
        let date = this.end_date.split("-");
        let year = date[0];
        let month = date[1];
        this.start_date = year + "-" + month + "-01";
        this.date[0] = year + "-" + month + "-01";
      }
      this.dateRangeTitle =
        moment(this.date[0]).format("ll") +
        " - " +
        moment(this.date[1]).format("ll");
      if (this.start_date > this.end_date) {
        // this.checkIfStartDateGreater();
        this.dateRangeTitle =
          moment(this.date[1]).format("ll") +
          " - " +
          moment(this.date[0]).format("ll");
      }
      if (this.end_date < this.start_date) {
        this.start_date = this.start_date
          ? this.start_date
          : moment(this.date[1]).format("YYYY-MM-DD");
        this.end_date = moment(this.date[0]).format("YYYY-MM-DD");
      } else if (this.start_date > this.end_date) {
        // this.checkIfStartDateGreater();
      }
      this.selectedDateTeamMembers();
      this.date = "";
      this.menu = false;
    },
    /**
     * This function is resposible for
     * return the count of total absent
     */
    totalAbsent(dateRange, attendence) {
      let absentCount = 0;
      let attendenceUser = attendence;
      dateRange.map((date) => {
        let checkAttendence = attendenceUser.find(
          (attendenceData) => attendenceData.date === date
        );
        if (!checkAttendence) {
          var dt = moment(date, "YYYY-MM-DD HH:mm:ss");
          let day_name = dt.format("dddd");
          let currentDate = moment().format("YYYY-MM-DD");
          if (date >= currentDate) {
            return "--";
          }
          if (day_name == "Saturday" || day_name == "Sunday") {
            return "Holiday";
          } else {
            absentCount = absentCount + 1;
          }
        }
      });
      return absentCount;
    },
    /**
     * This function is resposible for
     * export of Excel
     */
    exportToExcel(tblId) {
      this.exportImage = false;
      setTimeout(() => {
        var dt = new Date();
        var day = dt.getDate();
        var month = dt.getMonth() + 1;
        var year = dt.getFullYear();
        var hour = dt.getHours();
        var mins = dt.getMinutes();
        var postfix = day + "." + month + "." + year + "_" + hour + "." + mins;
        //creating a temporary HTML link element (they support setting file names)
        var a = document.createElement("a");
        //getting data from our div that contains the HTML table
        var data_type = "data:application/vnd.ms-excel;charset=utf-8";

        var table_html = $("#" + tblId)[0].outerHTML;
        //    table_html = table_html.replace(/ /g, '%20');
        table_html = table_html.replace(/<tfoot[\s\S.]*tfoot>/gim, "");
        var css_html =
          "<style>td {border: 0.5pt solid #c0c0c0} .tRight { text-align:right} .tLeft { text-align:left} </style>";
        //    css_html = css_html.replace(/ /g, '%20');
        a.href =
          data_type +
          "," +
          encodeURIComponent(
            "<html><head>" +
              css_html +
              "</" +
              "head><body>" +
              table_html +
              "</body></html>"
          );
        //setting the file name
        a.download = "exported_table_" + postfix + ".xls";
        //triggering the function
        a.click();
      }, 2000);
      //just in case, prevent default behaviour
      setTimeout(() => {
        this.exportImage = true;
      }, 2000);
    },

    exportToCSV(tblId) {
      this.exportImage = false;
      setTimeout(() => {
        var rows = document.querySelectorAll("#" + tblId + " tr");
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
          var row = [],
            cols = rows[i].querySelectorAll("td, th");
          for (var j = 0; j < cols.length; j++) {
            var data = cols[j].innerText
              .replace(/(\r\n|\n|\r)/gm, "")
              .replace(/(\s\s)/gm, " ");
            data = data.replace(/"/g, '""');
            row.push('"' + data + '"');
          }
          csv.push(row.join(";"));
        }
        var csv_string = csv.join("\n");
        // Download it
        var filename = "Stafftimerapp attendence.csv";
        var link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
        );
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, 2000);
      setTimeout(() => {
        this.exportImage = true;
      }, 2000);
    },
    /**
     * This function is resposible for
     * sorting of data
     */
    sortData(col = "name") {
      if (this.group_by != "none") {
        let data = this.attendenceGroupBy;
        this.selectedOrder = this.selectedOrder == "desc" ? "asc" : "desc";
        this.attendenceGroupBy = _.orderBy(data, [col], [this.selectedOrder]);
      } else {
        let data = this.attendancedata;
        this.selectedOrder = this.selectedOrder == "desc" ? "asc" : "desc";
        this.attendancedata = _.orderBy(data, [col], [this.selectedOrder]);
      }
    },
    /**
     * This function is resposible for
     * group by according to given data.
     */
    groupByAttendence() {
      let groupBy = this.group_by;
      let attendence = _.groupBy(this.attendancedata, function (attendnce) {
        if (groupBy == "name") {
          return attendnce.id;
        }
        if (groupBy == "department") {
          return attendnce.department_name;
        }
        if (groupBy == "date") {
          return attendnce.date;
        }
      });
      this.groupByDataSet(attendence);
    },
    groupByDataSet(attendence) {
      this.attendenceGroupBy = [];
      let data;
      Object.values(attendence).map((attendence) => {
        let singleRecored = attendence["0"];
        let date;
        if (this.group_by == "date") {
          date = singleRecored.date;
        } else {
          date = `${this.start_date} - ${this.end_date}`;
        }
        let time = this.totalTime(attendence),
          data = {
            id: singleRecored.id,
            name: singleRecored.name,
            date: date,
            department_name: singleRecored.department_name
              ? singleRecored.department_name
              : "Miscellaneous",
            total_time: this.totalTime(attendence),
            total_min: this.convertHours2Minutes(time),
          };
        this.attendenceGroupBy.push(data);
      });
    },
    /**
     * This function is resposible for
     * return total time
     */
    totalTime(attendenceDate) {
      let totalMinuts = attendenceDate.reduce(function (prev, cur) {
        let totalTime = moment.duration(cur.total_time).asMinutes();
        return prev + totalTime;
      }, 0);
      return this.timeConvert(totalMinuts);
    },
    /**
     * This function is resposible for
     * conversion of covert horss to min
     */
    convertHours2Minutes(timeInHour) {
      var timeParts = timeInHour.split(":");
      return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    },
    userData(id) {
      let member = this.$store.state.custom.get_team_members_list.find(
        (data) => data.id == id
      );
      member = member ? member.image : null;
      return member;
    },
    /**
     * This function is resposible for
     * get attendance list (api)
     */
    get_attendance_List() {
      let member;
      if (this.selectedMember == "" || this.selectedMember == null) {
        member = "all";
      } else {
        member = this.selectedMember;
      }
      let data = {
        start_date: this.start_date,
        end_date: this.end_date,
        company_id: this.$route.params.id,
        member_id: member,
        department_id: this.department,
      };
      this.$store.commit("custom/toggle_loader", true);
      this.$store
        .dispatch("custom/get_attendance", data)
        .then((response) => {
          this.attendancedata = _.orderBy(
            response.data.attendence_report,
            ["name", "date"],
            ["asc"]
          );
          this.createTotalMinutes();
          if (this.group_by != "none") {
            this.groupByAttendence();
          }
          this.$store.commit("custom/toggle_loader", false);
          this.tableShow = true;
          this.toggle_loader = true;
          this.makeData();
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
          this.toggle_loader = true;
        });
    },
    createTotalMinutes() {
      for (let i = 0; i < this.attendancedata.length; i++) {
        let totalMinutes = this.convertHours2Minutes(
          this.attendancedata[i].total_time
        );
        this.attendancedata[i].total_min = totalMinutes;
      }
      return;
    },
    /**
     * This function is resposible for
     * time converter
     */
    timeConvert(n) {
      let num = n;
      let hours = num / 60;
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      if (rhours.toString().length < 2 || rminutes.toString().length < 2) {
        if (rminutes.toString().length < 2 && rhours.toString().length < 2) {
          return `0${rhours}:0${rminutes}`;
        }
        if (rminutes.toString().length < 2 && !rhours.toString().length < 2) {
          return `${rhours}:0${rminutes}`;
        }
        if (!rminutes.toString().length < 2 && rhours.toString().length < 2) {
          return `0${rhours}:${rminutes}`;
        }
      }
      return `${rhours}:${rminutes}`;
    },
    // End attendence Group By

    makeData() {
      this.rangeDate = this.getDaysDiff(this.start_date, this.end_date);
      this.excellData = _.groupBy(this.attendancedata, function (attendnce) {
        return attendnce.id;
      });
    },
    getDaysDiff(start_date, end_date, date_format = "YYYY-MM-DD") {
      const getDateAsArray = (date) => {
        return moment(date.split(/\D+/), date_format);
      };
      const diff =
        getDateAsArray(end_date).diff(getDateAsArray(start_date), "days") + 1;
      const dates = [];
      for (let i = 0; i < diff; i++) {
        const nextDate = getDateAsArray(start_date).add(i, "day");
        const isWeekEndDay = nextDate.isoWeekday() > 7;
        if (!isWeekEndDay) dates.push(nextDate.format(date_format));
      }
      return dates;
    },
    calculateDate(date, attendenceDate) {
      let attendence = attendenceDate.find(
        (attendenceData) => attendenceData.date === date
      );
      let get_resign_date = attendenceDate.find(
        (attendenceData) => attendenceData.resign_date !== null
      );
      if (get_resign_date) {
        if (date >= get_resign_date.resign_date) {
          return "--";
        }
      }
      if (attendence) {
        return (
          attendence.check_in +
          " - " +
          attendence.check_out +
          " = " +
          attendence.total_time
        );
      } else {
        var dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        let day_name = dt.format("dddd");
        let currentDate = moment().format("YYYY-MM-DD");
        if (date >= currentDate) {
          return "--";
        }
        if (day_name == "Saturday" || day_name == "Sunday") {
          return "Holiday";
        } else {
          return "Absent";
        }
      }
    },
    /**
     * This function is resposible for
     * get deparment list (api call)
     */
    get_department_list() {
      let data = {
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/getDeaprtmentList", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.departmentList = _.orderBy(
            response.data.department,
            ["name"],
            ["asc"]
          );
          this.$store.commit("custom/set_departments", this.departmentList);
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response) {
            if (error.response.data.message === "Unauthenticated.") {
              this.$store.commit("custom/resetState");
              this.$store.dispatch("auth/reset").then(() => {
                return this.$router.push({ name: "login" });
              });
            }
          }
        });
    },
    /**
     * This function is resposible for
     * custom date range selection
     */
    checkIfCustomDate(date) {
      date.value =
        moment().subtract(1, "days").format("MMM D, YYYY") +
        "-" +
        moment().subtract(1, "days").format("MMM D, YYYY");
      this.dateRangeTitle = date.value = "Yesterday";
      if (date.id === 1) {
        let start = moment().subtract(1, "days").format("YYYY-MM-DD");
        let end = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.dateRangeTitle = "Yesterday";
      }
      if (date.id === 2) {
        date.value =
          moment().subtract(6, "days").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 3) {
        date.value =
          moment().subtract(29, "days").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 4) {
        date.value =
          moment().startOf("month").format("MMM D, YYYY") +
          "-" +
          moment().endOf("month").format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 5) {
        date.value =
          moment().subtract(1, "month").startOf("month").format("MMM D, YYYY") +
          "-" +
          moment().subtract(1, "month").endOf("month").format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 6) {
        date.value =
          moment().subtract(1, "year").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 7) {
        this.modal_2 = true;
        // this.showPicker = true;
        // this.dateRangeTitle = "Custom Range";
        // document.getElementById("range-date").click();
      } else {
        this.showPicker = false;
        this.setDates(date);
        // this.get_attendance_List(this.date);
      }
      document.getElementById("cRange").click();
    },
    setDates(date) {
      if (date.id === 1) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (date.id === 2) {
        this.start_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 3) {
        this.start_date = moment().subtract(29, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 4) {
        this.start_date = moment().startOf("month").format("YYYY-MM-DD");
        this.end_date = moment().endOf("month").format("YYYY-MM-DD");
      } else if (date.id === 5) {
        this.start_date = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.end_date = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (date.id === 6) {
        this.start_date = moment().subtract(1, "year").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      }
      this.selectedDateTeamMembers();
    },
  },
};
</script>
<style scoped>
.error-img {
  height: 300px;
}
.error_text {
  font-size: larger;
  font-weight: 500;
  color: #a9bcdb;
  margin-top: 20px;
  text-align: center;
}
.selected-date {
  border: 1px solid #e9f1fe !important;
  margin-top: -10px !important;
  font-size: 12px !important;
  display: flex !important;
  height: 40px !important;
  padding: 9px !important;
  border-radius: 3px;
  background-color: #fff;
}
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}
.table-div .table-sticky-col-name {
  left: 0;
  width: 209px;
}
.custom-padding {
  height: 19%;
}
</style>
<style>
.timesheet-image {
  width: 31px;
  border-radius: 100%;
  max-width: 65px;
  max-height: 65px;
  border: none;
  margin-left: -2px !important;
}
.display {
  font-size: 17px !important;
  color: white !important;
  cursor: pointer;
}
</style>
